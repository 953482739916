import React from "react";
import { Card, Image, Segment } from "semantic-ui-react";

import aboutBanner from "../img/aboutBanner.png";

function About() {
  return (
    <Segment raised>
      <Card fluid>
        <Image src={aboutBanner} />
        <Card.Content>
          <Card.Header>Welcome to CreatorConnect!</Card.Header>
          <Card.Description>
            <p>
              The biggest issue faced by channels both new and old, is "how do I
              get my people to view my videos?"
            </p>
            <p>
              The best way to do this is to collab on a videos with another
              channel - this benifits both channels as it gives you direct
              access to the otehr channel's audience.
            </p>
            <p>
              <em>CreaterConnect</em> is a website to help facilitate such
              collaborations - the first and only website of it's kind. Using{" "}
              <em>CreaterConnect</em> is simple - log in with your YouTube
              account and you can begin posting collab ads or respond to them.
              Logging in is done via Google, and we do not have any access to
              your private Google/YouTube data and can not make any changes to
              your account.
            </p>
            <p>
              When posting a collab, you can restrict who can apply by age,
              location, and even by subscriber count.
            </p>
          </Card.Description>
        </Card.Content>
      </Card>
    </Segment>
  );
}

export default About;
