import React from "react";
import { Card, Image, Segment } from "semantic-ui-react";

import roadmapBanner from "../img/roadmapBanner.png";

function Roadmap() {
  return (
    <Segment raised>
      <Card fluid>
        <Image src={roadmapBanner} />
        <Card.Content>
          <Card.Header>Product Roadmap</Card.Header>
          <Card.Description>
            <p>
              We have a lot of cool things planned, so we hope you stick around.
            </p>
          </Card.Description>
        </Card.Content>
      </Card>
    </Segment>
  );
}

export default Roadmap;
