import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Card, Icon, Item, Message, Segment } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { AuthContext } from "../context/auth";
import DeleteMessageButton from "../components/DeleteMessageButton";

function ReadMesssage(props) {
  const { user } = useContext(AuthContext);

  const messageId = props.match.params.messageId;

  const { data: { getCollabMessage: collabMessage } = {}, loading } = useQuery(
    FETCH_COLLAB_MESSAGE_QUERY,
    {
      variables: {
        messageId,
      },
      fetchPolicy: "no-cache",
    }
  );

  function deleteMessageCallback() {
    props.history.push("/inbox");
  }

  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum !== 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  return (
    <>
      {user ? (
        <Segment raised>
          <Item.Group divided>
            {loading ? (
              <h3>Loading message...</h3>
            ) : (
              collabMessage && (
                <>
                  <Item>
                    <Item.Image
                      size="tiny"
                      src={collabMessage.otherChannel.channelThumbnail}
                    />
                    <Item.Content verticalAlign="middle">
                      <Link
                        to={`/channel/${collabMessage.otherChannel.channelId}/collabs`}
                      >
                        <div className="gigChannelName">
                          {collabMessage.otherChannel.channelName}{" "}
                          <span className="gigSubCount">
                            {abbreviateNumber(
                              collabMessage.otherChannel.channelSubCount
                            )}{" "}
                            Subscribers
                          </span>
                        </div>
                      </Link>
                      <Item.Header>
                        RE: {collabMessage.collabDetails.title}
                      </Item.Header>
                    </Item.Content>
                  </Item>
                  <Item>
                    <Link to="/inbox">
                      <Icon name="angle double left" /> Back to Inbox
                    </Link>
                  </Item>
                  <Item>
                    <Card fluid>
                      <Card.Content>{collabMessage.message}</Card.Content>
                    </Card>
                  </Item>
                  <Item>
                    <DeleteMessageButton
                      messageId={messageId}
                      callback={deleteMessageCallback}
                    />
                  </Item>
                </>
              )
            )}
          </Item.Group>
        </Segment>
      ) : (
        <Message
          icon="warning sign"
          header="You must be logged in to view this page."
          content="Use the button in the header to log in with your YouTube account."
        />
      )}
    </>
  );
}

const FETCH_COLLAB_MESSAGE_QUERY = gql`
  query($messageId: ID!) {
    getCollabMessage(collabMessageId: $messageId) {
      id
      otherChannel {
        channelId
        channelName
        channelThumbnail
        channelSubCount
      }
      sentAt
      collabDetails {
        id
        title
      }
      message
      isRead
    }
  }
`;

export default ReadMesssage;
