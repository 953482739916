import React, { useContext, useState } from "react";
import {
  Menu,
  Container,
  Icon,
  Image,
  Dropdown,
  Button,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import CCLogo from "../img/logo.png";
import googleBtn from "../img/googleBtn.png";

import { AuthContext } from "../context/auth";
import InboxButton from "./InboxButton";

function Header() {
  const { user, logout } = useContext(AuthContext);
  const { data: { getLoginLink } = {} } = useQuery(FETCH_LOGIN_LINK_QUERY);

  const pathname = window.location.pathname;
  const path = pathname === "/" ? "collabs" : pathname.substr(1);
  const [activeItem, setActiveItem] = useState(path);

  const handleItemClick = (e, { name }) => setActiveItem(name);

  let linkMarkup;

  if (!getLoginLink) {
    linkMarkup = <p>Loading</p>;
  } else {
    linkMarkup = (
      <Image src={googleBtn} className="googleBtn" href={getLoginLink} />
    );
  }

  return (
    <>
      <div>
        <Menu stackable secondary className="topHeader">
          <Container>
            <Menu.Item>
              <Image src={CCLogo} className="headerLogo" as={Link} to="/" />
            </Menu.Item>
            {user ? (
              <Menu.Menu position="right" className="userMenu">
                <InboxButton />
                <Dropdown item icon="user circle">
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      to={`/channel/${user.channel.channelId}`}
                    >
                      My Account
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={`/channel/${user.channel.channelId}/collabs`}
                    >
                      My Posts
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            ) : (
              <Menu.Item position="right">{linkMarkup}</Menu.Item>
            )}
          </Container>
        </Menu>
        <Menu stackable pointing secondary className="topNav" size="large">
          <Container>
            <Menu.Item
              name="collabs"
              active={activeItem === "collabs"}
              onClick={handleItemClick}
              as={Link}
              to="/"
            >
              <Icon name="video" /> Collabs
            </Menu.Item>
            <Menu.Item
              name="about"
              active={activeItem === "about"}
              onClick={handleItemClick}
              as={Link}
              to="/about"
            >
              <Icon name="info circle" /> About
            </Menu.Item>
            <Menu.Item
              name="roadmap"
              active={activeItem === "roadmap"}
              onClick={handleItemClick}
              as={Link}
              to="/roadmap"
            >
              <Icon name="road" /> Roadmap
            </Menu.Item>
            {user && (
              <Menu.Item position="right">
                <Button primary as={Link} to="/collabs/post">
                  Post a Collab
                </Button>
              </Menu.Item>
            )}
          </Container>
        </Menu>
      </div>
    </>
  );
}

const FETCH_LOGIN_LINK_QUERY = gql`
  query {
    getLoginLink
  }
`;

export default Header;
