import React, { useContext } from "react";
import { Card, Message, Segment } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/auth";
import ApplyForm from "../components/ApplyForm";

function CollabApply(props) {
  const { user } = useContext(AuthContext);

  const postId = props.match.params.postId;

  const { data: { getCollabPost } = {} } = useQuery(FETCH_COLLAB_QUERY, {
    variables: {
      postId,
    },
  });

  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum !== 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  let postMarkup;
  let formMarkup;

  if (!getCollabPost) {
    postMarkup = <p>Loading...</p>;
  } else {
    const {
      //id,
      title,
      //body,
      category,
      //minAge,
      minSubCount,
      //location,
      //collabLocation,
      //applyUntil,
      //postedBy,
      //createdAt,
      channel,
    } = getCollabPost;

    const channelBanner = channel.channelBanner + "=w1920";

    if (!user) {
      formMarkup = (
        <Message
          icon="warning sign"
          header="You must be logged in to apply for this collab"
          content="Use the button in the header to log in with your YouTube account."
        />
      );
    } else {
      const isCorrectSubCount =
        minSubCount !== 0 || user.channel.channelSubCount < minSubCount
          ? false
          : true;

      if (!isCorrectSubCount) {
        formMarkup = (
          <Message negative>
            <Message.Header>You do not quality for this collab</Message.Header>
            <p>
              You must have {minSubCount} or more subscribers to apply for this
              collab. You currently have {user.channel.channelSubCount}.
            </p>
          </Message>
        );
      } else {
        formMarkup = <ApplyForm collabDetails={getCollabPost} />;
      }
    }

    postMarkup = (
      <>
        <Card fluid>
          <div
            className="channelBanner"
            style={{ backgroundImage: `url(${channelBanner})` }}
          ></div>
          <Card.Content>
            <Card.Header>Apply for Collab: {title}</Card.Header>
            <Link to={`/channel/${channel.channelId}/collabs`}>
              <div className="gigChannelName">
                {channel.channelName}{" "}
                <span className="gigSubCount">
                  {abbreviateNumber(channel.channelSubCount)} Subscribers
                </span>
                <span className="gigLocation">{channel.location}</span>
              </div>
            </Link>
            <Card.Content extra>
              <p className="collabCategory">
                <span>Category:</span> {category} video
              </p>
            </Card.Content>

            <Card.Description>{formMarkup}</Card.Description>
          </Card.Content>
        </Card>
      </>
    );
  }

  return <Segment raised>{postMarkup}</Segment>;
}

const FETCH_COLLAB_QUERY = gql`
  query($postId: ID!) {
    getCollabPost(collabPostId: $postId) {
      id
      title
      body
      category
      minAge
      minSubCount
      location
      collabLocation
      applyUntil
      postedBy
      createdAt
      channel {
        channelId
        channelName
        channelDescription
        channelThumbnail
        channelSubCount
        channelViewCount
        channelVideoCount
        channelBanner
      }
    }
  }
`;

export default CollabApply;
