import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Icon, Item, Label, Message, Segment } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { AuthContext } from "../context/auth";

function Inbox(props) {
  const { user } = useContext(AuthContext);

  const {
    loading,
    data: { getCollabInbox: inboxMessages } = {},
  } = useQuery(FETCH_INBOX_QUERY, { fetchPolicy: "no-cache" });

  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum !== 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  return (
    <>
      {user ? (
        <Segment raised>
          <Item.Group divided>
            <Item>
              <h3>Your messages:</h3>
            </Item>
            {loading ? (
              <h3>Loading collabs...</h3>
            ) : (
              inboxMessages &&
              inboxMessages.applications.map((inboxMessage) => (
                <Item key={inboxMessage.id}>
                  <Item.Image
                    size="tiny"
                    src={inboxMessage.otherChannel.channelThumbnail}
                  />
                  <Item.Content verticalAlign="middle">
                    <Link
                      to={`/channel/${inboxMessage.otherChannel.channelId}/collabs`}
                    >
                      <div className="gigChannelName">
                        {inboxMessage.otherChannel.channelName}{" "}
                        <span className="gigSubCount">
                          {abbreviateNumber(
                            inboxMessage.otherChannel.channelSubCount
                          )}{" "}
                          Subscribers
                        </span>
                      </div>
                    </Link>
                    <Item.Header as={Link} to={`/inbox/${inboxMessage.id}`}>
                      RE: {inboxMessage.collabDetails.title}
                    </Item.Header>
                    <Item.Content>
                      {inboxMessage.isRead ? (
                        <>
                          <Label
                            className="readUnread"
                            as={Link}
                            to={`/inbox/${inboxMessage.id}`}
                          >
                            <Icon name="envelope open" /> Read
                          </Label>
                        </>
                      ) : (
                        <>
                          <Label
                            className="readUnread"
                            color="green"
                            as={Link}
                            to={`/inbox/${inboxMessage.id}`}
                          >
                            <Icon name="mail" /> Unread
                          </Label>
                        </>
                      )}
                    </Item.Content>
                  </Item.Content>
                </Item>
              ))
            )}
          </Item.Group>
        </Segment>
      ) : (
        <Message
          icon="warning sign"
          header="You must be logged in to view this page."
          content="Use the button in the header to log in with your YouTube account."
        />
      )}
    </>
  );
}

const FETCH_INBOX_QUERY = gql`
  {
    getCollabInbox {
      applications {
        id
        otherChannel {
          channelId
          channelName
          channelThumbnail
          channelSubCount
        }
        sentAt
        collabDetails {
          id
          title
        }
        isRead
      }
    }
  }
`;

export default Inbox;
