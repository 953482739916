import React from "react";
import { Item, Segment } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import CollabCard from "../components/CollabCard";

function ChannelCollabs(props) {
  const channelId = props.match.params.channelId;

  const { loading, data: { getCollabPosts: collabPosts } = {} } = useQuery(
    FETCH_COLLABS_QUERY,
    {
      variables: {
        channelId,
      },
    }
  );

  return (
    <Segment raised>
      {loading ? (
        ""
      ) : (
        <div
          class="channelBanner"
          style={{
            backgroundImage: `url(${collabPosts[0].channel.channelBanner}=w1920)`,
          }}
        ></div>
      )}
      <Item.Group divided>
        <Item>
          <h3>Collabs for this channel:</h3>
        </Item>
        {loading ? (
          <h3>Loading collabs...</h3>
        ) : (
          collabPosts &&
          collabPosts.map((collabPost) => (
            <Item key={collabPost.id}>
              <CollabCard collabPost={collabPost} />
            </Item>
          ))
        )}
      </Item.Group>
    </Segment>
  );
}

const FETCH_COLLABS_QUERY = gql`
  query($channelId: String!) {
    getCollabPosts(channelId: $channelId) {
      id
      title
      body
      category
      minAge
      minSubCount
      location
      collabLocation
      applyUntil
      postedBy
      createdAt
      channel {
        channelId
        channelName
        channelDescription
        channelThumbnail
        channelSubCount
        channelViewCount
        channelVideoCount
        channelBanner
      }
    }
  }
`;

export default ChannelCollabs;
