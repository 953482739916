import gql from "graphql-tag";

export const FETCH_COLLABS_QUERY = gql`
  {
    getCollabPosts {
      id
      title
      body
      category
      minAge
      minSubCount
      location
      collabLocation
      applyUntil
      postedBy
      createdAt
      channel {
        channelId
        channelName
        channelDescription
        channelThumbnail
        channelSubCount
        channelViewCount
        channelVideoCount
        channelBanner
      }
    }
  }
`;

export const FETCH_PROFILE_QUERY = gql`
  query($channelId: String!) {
    getProfileInfo(channelId: $channelId) {
      firstName
      lastName
      email
      location
    }
  }
`;

export const FETCH_COLLAB_INBOX_QUERY = gql`
  {
    getCollabInbox {
      applicants {
        id
        otherChannel {
          channelId
          channelName
          channelDescription
          channelThumbnail
          channelSubCount
          channelViewCount
          channelVideoCount
          channelBanner
          location
        }
        sentAt
        collabDetails {
          id
          title
          body
          category
          minAge
          minSubCount
          location
          collabLocation
          applyUntil
          createdAt
        }
        collabOwner
        message
      }
      applications {
        id
        otherChannel {
          channelId
          channelName
          channelDescription
          channelThumbnail
          channelSubCount
          channelViewCount
          channelVideoCount
          channelBanner
          location
        }
        sentAt
        collabDetails {
          id
          title
          body
          category
          minAge
          minSubCount
          location
          collabLocation
          applyUntil
          createdAt
        }
        collabOwner
        message
        isRead
        inInbox
        inOutbox
      }
      unreadCount
    }
  }
`;

export const FETCH_COLLAB_UNREAD_COUNT_QUERY = gql`
  {
    getCollabInbox {
      unreadCount
    }
  }
`;
