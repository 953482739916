import React, { useContext } from "react";
import { Button, Card, Segment } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

import DeleteCollabButton from "../components/DeleteCollabButton";
import { AuthContext } from "../context/auth";
import MyPopup from "../utils/MyPopup";

function CollabDetails(props) {
  const { user } = useContext(AuthContext);

  const postId = props.match.params.postId;

  const { data: { getCollabPost } = {} } = useQuery(FETCH_COLLAB_QUERY, {
    variables: {
      postId,
    },
  });

  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum !== 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  function deleteCollabCallback() {
    props.history.push("/");
  }

  let postMarkup;
  if (!getCollabPost) {
    postMarkup = <p>Loading collab...</p>;
  } else {
    const {
      id,
      title,
      body,
      category,
      minAge,
      minSubCount,
      //location,
      collabLocation,
      //applyUntil,
      //postedBy,
      //createdAt,
      channel,
    } = getCollabPost;

    let applyButton;
    if (!user) {
      applyButton = (
        <MyPopup content={"You must be logged in to apply"}>
          <Button color="grey">Apply</Button>
        </MyPopup>
      );
    } else if (user.channel.channelId === channel.channelId) {
      applyButton = (
        <MyPopup content={"You can't apply for your own collab"}>
          <Button color="grey">Apply</Button>
        </MyPopup>
      );
    } else {
      applyButton = (
        <Button primary as={Link} to={`/collab/${id}/apply`}>
          Apply
        </Button>
      );
    }

    const channelBanner = channel.channelBanner + "=w1920";
    postMarkup = (
      <>
        <Card fluid>
          <div
            className="channelBanner"
            style={{ backgroundImage: `url(${channelBanner})` }}
          ></div>
          <Card.Content>
            <Link to={`/channel/${channel.channelId}/collabs`}>
              <div className="gigChannelName">
                {channel.channelName}{" "}
                <span className="gigSubCount">
                  {abbreviateNumber(channel.channelSubCount)} Subscribers
                </span>
                <span className="gigLocation">{channel.location}</span>
              </div>
            </Link>

            <Card.Header>{title}</Card.Header>
            <Card.Content extra>
              <p className="collabCategory">
                <span>Category:</span> {category} video
              </p>
            </Card.Content>
            <Card.Description>
              <p className="collabDescription">{body}</p>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className="listingInfo">
              <strong>This collab is open to: </strong>
              <ul>
                <li>
                  Creators age {minAge} and over{" "}
                  {minAge < 18 && <>(Those under 18 need parental consent)</>}
                </li>
                <li>
                  Channels with{" "}
                  {minSubCount === 0 ? (
                    <>any subscriber count</>
                  ) : (
                    <>{minSubCount}+ subscribers</>
                  )}
                </li>
                <li>Creators located in {collabLocation}</li>
              </ul>
            </div>
          </Card.Content>
          <Card.Content extra>
            {applyButton}
            <Button
              basic
              color="teal"
              href={`https://www.youtube.com/channel/${channel.channelId}?sub_confirmation=1`}
              target="_blank"
              content="View Channel"
            />
            {user && user.channel.channelId === channel.channelId && (
              <DeleteCollabButton postId={id} callback={deleteCollabCallback} />
            )}
          </Card.Content>
        </Card>
      </>
    );
  }

  return <Segment raised>{postMarkup}</Segment>;
}

const FETCH_COLLAB_QUERY = gql`
  query($postId: ID!) {
    getCollabPost(collabPostId: $postId) {
      id
      title
      body
      category
      minAge
      minSubCount
      location
      collabLocation
      applyUntil
      postedBy
      createdAt
      channel {
        channelId
        channelName
        channelDescription
        channelThumbnail
        channelSubCount
        channelViewCount
        channelVideoCount
        channelBanner
      }
    }
  }
`;

export default CollabDetails;
