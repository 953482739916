import React from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { Container } from "semantic-ui-react";

import Home from "./pages/Home";
import About from "./pages/About";
import Roadmap from "./pages/Roadmap";
import ChannelCollabs from "./pages/ChannelCollabs";
import PostCollab from "./pages/PostCollab";
import GLogin from "./pages/GLogin";
import CollabDetails from "./pages/CollabDetails";
import ChannelProfile from "./pages/ChannelProfile";
import CollabApply from "./pages/CollabApply";
import Inbox from "./pages/Inbox";
import ReadMesssage from "./pages/ReadMesssage";
import Privacy from "./pages/Privacy";

import "./App.css";
import Header from "./components/Header";

import { AuthProvider } from "./context/auth";
import AuthRoute from "./utils/AuthRoute";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Container className="contentArea">
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/roadmap" component={Roadmap} />
          <Route exact path="/collabs" component={Home} />
          <Route exact path="/collabs/post" component={PostCollab} />
          <Route exact path="/collab/:postId" component={CollabDetails} />
          <Route exact path="/collab/:postId/apply" component={CollabApply} />
          <Route exact path="/channel/:channelId" component={ChannelProfile} />
          <Route exact path="/inbox/" component={Inbox} />
          <Route exact path="/inbox/:messageId" component={ReadMesssage} />
          <Route
            exact
            path="/channel/:channelId/collabs"
            component={ChannelCollabs}
          />
          <AuthRoute exact path="/glogin" component={GLogin} />
          <Route exact path="/privacy" component={Privacy} />
          <Link to="/privacy">Privacy Policy</Link>
        </Container>
      </Router>
    </AuthProvider>
  );
}

export default App;
