import React, { useContext } from "react";

import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Message } from "semantic-ui-react";

import { AuthContext } from "../context/auth";

function GLogin(props) {
  const context = useContext(AuthContext);
  const { search } = useLocation();
  const { code, error } = queryString.parse(search);

  const authCode = code;
  const authError = error;

  const { data: { googleLogin: User } = {} } = useQuery(GOOGLE_LOGIN, {
    variables: {
      authCode,
    },
    onError: (e) => {
      console.log(e.message);
      props.history.push("/");
    },
  });
  if (authError) {
    return (
      <Message
        icon="warning sign"
        header="Login with Google was unsuccessful, please try again."
        content={`Error: ${authError}`}
      />
    );
  }
  if (User) {
    context.login(User);
    props.history.push("/");
  }
  return null;
}

const GOOGLE_LOGIN = gql`
  query googleLogin($authCode: String!) {
    googleLogin(googleCode: $authCode) {
      channel {
        channelId
        channelName
        channelDescription
        channelThumbnail
        channelSubCount
        channelViewCount
        channelVideoCount
        channelBanner
      }
      authToken
    }
  }
`;

export default GLogin;
