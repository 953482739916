import React from "react";
import { Link } from "react-router-dom";
import { Icon, Label, Menu } from "semantic-ui-react";
import gql from "graphql-tag";

import { useQuery } from "@apollo/client";

function InboxButton() {
  const { data: { getCollaUnread } = {} } = useQuery(FETCH_UNREAD_QUERY, {
    fetchPolicy: "no-cache",
  });

  let unreadLabel = <></>;

  if (getCollaUnread) {
    if (getCollaUnread > 0) {
      unreadLabel = (
        <Label className="inboxCount" color="red" floating>
          {getCollaUnread}
        </Label>
      );
    }
  }

  return (
    <>
      <Menu.Item as={Link} to={"/inbox/"}>
        <Icon name="inbox" />
        {unreadLabel}
      </Menu.Item>
    </>
  );
}

/*
const UNREAD_SUBSCRIPTION = gql`
  subscription unreadMessages($channelId: string!) {
    unreadMessages(channelId: $channelId) {
      unreadCount
    }
  }
`;
*/

const FETCH_UNREAD_QUERY = gql`
  query {
    getCollaUnread
  }
`;

export default InboxButton;
