import React, { useContext } from "react";
import { Item, Segment } from "semantic-ui-react";
import { useQuery } from "@apollo/client";

import { FETCH_PROFILE_QUERY } from "../utils/graphql";
import ProfileForm from "../components/ProfileForm";
import { AuthContext } from "../context/auth";

function ChannelCollabs(props) {
  const channelId = props.match.params.channelId;
  const { user } = useContext(AuthContext);

  if (!(user && user.channel.channelId === channelId)) {
    props.history.push(`/channel/${channelId}/collabs`);
  }

  const { data: { getProfileInfo } = {} } = useQuery(FETCH_PROFILE_QUERY, {
    variables: {
      channelId,
    },
    fetchPolicy: "no-cache",
  });

  return (
    <Segment raised>
      <div
        className="channelBanner"
        style={{
          backgroundImage: `url(${user.channel.channelBanner}=w1920)`,
        }}
      ></div>
      <h2>Account Settings</h2>
      <Item.Group>
        <Item>
          <Item.Image size="small" src={user.channel.channelThumbnail} />

          <Item.Content>
            {getProfileInfo ? (
              <ProfileForm profileInfo={getProfileInfo} channelId={channelId} />
            ) : (
              <>Loading...</>
            )}
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  );
}

export default ChannelCollabs;
