import React, { useContext } from "react";
import { Message, Segment } from "semantic-ui-react";

import { AuthContext } from "../context/auth";
import CollabForm from "../components/CollabForm";

function PostCollab() {
  const { user } = useContext(AuthContext);
  return (
    <>
      {user ? (
        <Segment raised>
          <CollabForm />
        </Segment>
      ) : (
        <Message
          icon="warning sign"
          header="You must be logged in before making a post."
          content="Use the button in the header to log in with your YouTube account."
        />
      )}
    </>
  );
}

export default PostCollab;
