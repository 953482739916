import React, { useState } from "react";
import { Button, Form, Message, Segment } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { useForm } from "../utils/hooks";

function ApplyForm(props) {
  const collabDetails = props.collabDetails;
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const { values, onChange, onSubmit } = useForm(applyCollabCallback, {
    message: "",
  });

  const [applyCollab, { loading, error }] = useMutation(APPLY_COLLAB_MUTATION, {
    variables: {
      ...values,
      to: collabDetails.channel.channelId,
      collabId: collabDetails.id,
      collabOwner: collabDetails.channel.channelId,
    },
    update(proxy, result) {
      setSuccess(true);
      values.message = "";
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
  });

  function applyCollabCallback() {
    applyCollab();
  }

  return (
    <Segment>
      <Message>
        <p>By applying, you confirm that:</p>
        <ul>
          <li>
            you are {collabDetails.minAge} years old or older{" "}
            {collabDetails.minAge < 18 && (
              <>
                <br />
                If you are under 18, you confirm that you have parental consent
              </>
            )}
          </li>
          {collabDetails.collabLocation !== "anywhere" &&
            collabDetails.collabLocation !== "Anywhere" && (
              <li>
                you are located in {collabDetails.collabLocation} or will be in{" "}
                {collabDetails.collabLocation} on the day of the collab
              </li>
            )}
        </ul>
      </Message>

      {error && (
        <div className="ui error message">
          <ul className="list">
            {Object.values(errors).map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>
      )}

      {success && (
        <div className="ui success message">Your message has been sent.</div>
      )}
      <Form onSubmit={onSubmit} noValidate className={loading ? "loading" : ""}>
        <Form.TextArea
          label="Your message"
          placeholder="Introduce yourself and state what you are bringing to the table. Don't forget your contact info!"
          name="message"
          onChange={onChange}
          value={values.message}
          error={error ? true : false}
        />

        <Button type="submit" color="teal">
          Send Message
        </Button>
      </Form>
    </Segment>
  );
}

const APPLY_COLLAB_MUTATION = gql`
  mutation sendCollabMessage(
    $to: String
    $collabId: String
    $collabOwner: String
    $message: String
  ) {
    sendCollabMessage(
      collabMessageInput: {
        to: $to
        collabId: $collabId
        collabOwner: $collabOwner
        message: $message
      }
    )
  }
`;

export default ApplyForm;
