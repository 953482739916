import React, { useContext, useState } from "react";
import { Button, Form, Input, Item, Segment } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { AuthContext } from "../context/auth";
import { Link } from "react-router-dom";
import { useForm } from "../utils/hooks";

function ProfileForm(props) {
  const { user } = useContext(AuthContext);

  const profileInfo = props.profileInfo;

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const { values, onChange, onSubmit } = useForm(updateProfileCallback, {
    firstName: profileInfo.firstName ? profileInfo.firstName : "",
    lastName: profileInfo.lastName ? profileInfo.lastName : "",
    email: profileInfo.email ? profileInfo.email : "",
    location: profileInfo.location ? profileInfo.location : "",
  });

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE_MUTATION, {
    variables: values,
    update(proxy, result) {
      setSuccess(true);
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
  });

  function updateProfileCallback() {
    updateProfile();
  }

  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum !== 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  return (
    <Segment>
      {Object.keys(errors).length > 0 && (
        <div className="ui error message">
          <ul className="list">
            {Object.values(errors).map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>
      )}
      {success && (
        <div className="ui success message">Profile successfully updated.</div>
      )}
      <Form onSubmit={onSubmit} noValidate className={loading ? "loading" : ""}>
        <Item.Group divided>
          <Item>
            {" "}
            <Item.Header>
              <Link to={`/channel/${user.channel.channelId}/collabs`}>
                <div className="gigChannelName">
                  {user.channel.channelName}{" "}
                  <span className="gigSubCount">
                    {abbreviateNumber(user.channel.channelSubCount)} Subscribers
                  </span>
                  <span className="gigLocation">{user.channel.location}</span>
                </div>
              </Link>
            </Item.Header>
          </Item>
          <Item>
            <Form.Field inline>
              <label>First Name (Private)</label>
              <Input
                placeholder="Contact First Name"
                name="firstName"
                onChange={onChange}
                defaultValue={
                  profileInfo.firstName ? profileInfo.firstName : ""
                }
                error={errors.firstName ? true : false}
              />
            </Form.Field>
          </Item>
          <Item>
            <Form.Field inline>
              <label>Last Name (Private)</label>
              <Input
                placeholder="Contact Last Name"
                name="lastName"
                onChange={onChange}
                defaultValue={profileInfo.lastName ? profileInfo.lastName : ""}
                error={errors.lastName ? true : false}
              />
            </Form.Field>
          </Item>
          <Item>
            <Form.Field inline>
              <label>Email Address (Private)</label>
              <Input
                placeholder="Contact Email"
                name="email"
                onChange={onChange}
                defaultValue={profileInfo.email ? profileInfo.email : ""}
                error={errors.email ? true : false}
              />
            </Form.Field>
          </Item>
          <Item>
            <Form.Field inline>
              <label>Location</label>
              <Input
                placeholder="State/Province, Country"
                name="location"
                onChange={onChange}
                defaultValue={profileInfo.location ? profileInfo.location : ""}
                error={errors.location ? true : false}
              />
            </Form.Field>
          </Item>
        </Item.Group>
        <Button type="submit" color="teal">
          Save Changes
        </Button>
        <Button type="reset">Reset</Button>
      </Form>
    </Segment>
  );
}

const UPDATE_PROFILE_MUTATION = gql`
  mutation updateUserProfile(
    $firstName: String
    $lastName: String
    $email: String
    $location: String
  ) {
    updateUserProfile(
      updateProfileInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        location: $location
      }
    ) {
      firstName
      lastName
      email
      location
    }
  }
`;

export default ProfileForm;
