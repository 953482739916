import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Item } from "semantic-ui-react";

import { AuthContext } from "../context/auth";
import DeleteCollabButton from "./DeleteCollabButton";

function CollabCard({
  collabPost: {
    id,
    title,
    body,
    category,
    minAge,
    minSubCount,
    collabLocation,
    applyUntil,
    postedBy,
    createdAt,
    channel,
  },
}) {
  const { user } = useContext(AuthContext);
  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = "";
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum !== 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        );
        var dotLessShortValue = (shortValue + "").replace(
          /[^a-zA-Z 0-9]+/g,
          ""
        );
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  return (
    <>
      <Item.Image size="small" src={channel.channelThumbnail} />
      <Item.Content verticalAlign="middle">
        <Link to={`/channel/${channel.channelId}/collabs`}>
          <div className="gigChannelName">
            {channel.channelName}{" "}
            <span className="gigSubCount">
              {abbreviateNumber(channel.channelSubCount)} Subscribers
            </span>
            <span className="gigLocation">{channel.location}</span>
          </div>
        </Link>
        <Item.Header>{title}</Item.Header>
        <Item.Extra>Video type: {category}</Item.Extra>
        <Item.Content>
          <div className="listingInfo">
            <strong>This collab is open to: </strong>
            <ul className="inlineBullets">
              <li>
                Channels with{" "}
                {minSubCount === 0 ? (
                  <>any subscriber count</>
                ) : (
                  <>{minSubCount}+ subscribers</>
                )}
              </li>
              <li>
                Creators located{" "}
                {collabLocation === "anywhere" ||
                collabLocation === "Anywhere" ? (
                  collabLocation
                ) : (
                  <>in {collabLocation}</>
                )}
              </li>
            </ul>
          </div>
        </Item.Content>
        <Item.Extra>
          <Button primary as={Link} to={`/collab/${id}`}>
            View Details
          </Button>
          <Button
            basic
            color="teal"
            href={`https://www.youtube.com/channel/${channel.channelId}?sub_confirmation=1`}
            target="_blank"
            content="View Channel"
          />
          {user && user.channel.channelId === channel.channelId && (
            <DeleteCollabButton postId={id} />
          )}
        </Item.Extra>
      </Item.Content>
    </>
  );
}

export default CollabCard;
