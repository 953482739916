import React from "react";
import { Item, Segment } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import CollabCard from "../components/CollabCard";

function Home() {
  const { loading, data: { getCollabPosts: collabPosts } = {} } = useQuery(
    FETCH_COLLABS_QUERY
  );

  return (
    <Segment raised>
      <Item.Group divided>
        <Item>
          <h3>These creators are looking for people to collaborate with:</h3>
        </Item>
        {loading ? (
          <h3>Loading collabs...</h3>
        ) : (
          collabPosts &&
          collabPosts.map((collabPost) => (
            <Item key={collabPost.id}>
              <CollabCard collabPost={collabPost} />
            </Item>
          ))
        )}
      </Item.Group>
    </Segment>
  );
}

const FETCH_COLLABS_QUERY = gql`
  {
    getCollabPosts {
      id
      title
      body
      category
      minAge
      minSubCount
      collabLocation
      applyUntil
      postedBy
      createdAt
      channel {
        channelId
        channelName
        channelDescription
        channelThumbnail
        channelSubCount
        channelViewCount
        channelVideoCount
        channelBanner
        location
      }
    }
  }
`;

export default Home;
