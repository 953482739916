import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Button, Confirm } from "semantic-ui-react";

import { FETCH_COLLABS_QUERY } from "../utils/graphql";
import MyPopup from "../utils/MyPopup";

function DeleteCollabButton({ postId, callback }) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [deleteCollab] = useMutation(DELETE_COLLAB_MUTATION, {
    update(proxy) {
      setConfirmOpen(false);
      const data = proxy.readQuery({ query: FETCH_COLLABS_QUERY });
      proxy.writeQuery({
        query: FETCH_COLLABS_QUERY,
        data: {
          getCollabPosts: data.getCollabPosts.filter((p) => p.id !== postId),
        },
      });
      if (callback) callback();
    },
    variables: {
      postId,
    },
  });

  return (
    <>
      <MyPopup content={"Delete this collab posting"}>
        <Button
          color="red"
          icon="trash"
          floated="right"
          onClick={() => setConfirmOpen(true)}
        />
      </MyPopup>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={deleteCollab}
      />
    </>
  );
}

const DELETE_COLLAB_MUTATION = gql`
  mutation deleteCollabPost($postId: ID!) {
    deleteCollabPost(collabPostId: $postId)
  }
`;

export default DeleteCollabButton;
