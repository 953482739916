import React from "react";
import { Card, Image, Segment } from "semantic-ui-react";

import privacyBanner from "../img/privacyBanner.png";

function Privacy() {
  return (
    <Segment raised>
      <Card fluid>
        <Image src={privacyBanner} />
        <Card.Content>
          <Card.Header>Privacy Policy</Card.Header>
          <Card.Description>
            <p>
              <strong>April 2021</strong>
            </p>
            <p>
              CreatorConnect is committed to user privacy and only collects the
              minimun person information needed to provide this service.
            </p>
            <p>Information We Collect</p>
            <p>
              We collect information about you to provide our services. The
              required information we collect are data public on your Google
              YouTube channel. The following is a list of all the information we
              recieve when you sign in with Google:
            </p>

            <ul>
              <li>YouTube Channel ID</li>
              <li>YouTube Channel Name</li>
              <li>YouTube Channel Description</li>
              <li>YouTube Channel Subscriber Count</li>
              <li>Number of Views on Your YouTube Channel</li>
              <li>Number of Videos on your YouTube Channel</li>
              <li>URL of your YouTube Channel Banner</li>
              <li>URL of your YouTube Channel Thumbnail</li>
            </ul>

            <p>
              By editing your profile on CreaterConnect, you may also volunteer
              the following personal information:
            </p>
            <ul>
              <li>First Name</li>
              <li>Last Name</li>
              <li>Email Address</li>
              <li>City/State/Country</li>
            </ul>

            <p>
              If you provide any of the above private information, all data othe
              than the city/state/country will be only visible to an
              administrator. All information provided to us will be used soely
              for the operation of this website and will not be used for
              marketing purposes, nor will it be shared or sold.
            </p>

            <p>
              <strong>Use of Cookies</strong>
            </p>
            <p>
              Cookies are small pieces of data that are stored on your computer,
              mobile phone, or other device when you first visit a page. Cookies
              are used on CreatorConnect solely for the purpose of
              authenticating a user and keeping track of user settings.
            </p>
            <p>
              <strong>Device Information</strong>
            </p>
            <p>
              Device information my be recorded by our web servers and
              analyitics provider. This data is used to improve site
              functionality.
            </p>
            <p>
              <strong>How We Use Information</strong>
            </p>
            <p>
              The data we collect from Google is used solely to provide our
              services. Your YouTube Channel inforamtion is how you will be
              identified on CreatorConnect, and will be used to see if you
              qualify for a particular creator collaboration.
            </p>
            <p>
              The voluntary private data we collect will be used to to contact
              you if you have new messages on the platform.
            </p>
            <p>
              <strong>How We Share Information</strong>
            </p>
            <p>
              When you post a collaboration request or apply for a collboration,
              your public YouTube channel information will be included with the
              post or message. You can delete your posted collaborations at
              anytime should no longer want them on the platform.
            </p>
            <p>We do not share your information outside of the platform.</p>
          </Card.Description>
        </Card.Content>
      </Card>
    </Segment>
  );
}

export default Privacy;
