import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Button, Confirm } from "semantic-ui-react";

import MyPopup from "../utils/MyPopup";

function DeleteMessageButton({ messageId, callback }) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [deleteMessage] = useMutation(DELETE_MESSAGE_MUTATION, {
    update(proxy) {
      setConfirmOpen(false);
      if (callback) callback();
    },
    variables: {
      messageId,
    },
  });

  return (
    <>
      <MyPopup content={"Delete this message posting"}>
        <Button
          color="red"
          icon="trash"
          floated="right"
          content="Delete Message"
          onClick={() => setConfirmOpen(true)}
        />
      </MyPopup>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={deleteMessage}
      />
    </>
  );
}

const DELETE_MESSAGE_MUTATION = gql`
  mutation deleteMessage($messageId: ID!) {
    deleteMessage(messageId: $messageId)
  }
`;

export default DeleteMessageButton;
