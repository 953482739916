import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { useForm } from "../utils/hooks";

const categories = [
  { key: "Animation", text: "Animation", value: "Animation" },
  { key: "Anime", text: "Anime", value: "Anime" },
  { key: "Beauty & Makeup", text: "Beauty & Makeup", value: "Beauty & Makeup" },
  { key: "Business", text: "Business", value: "Business" },
  { key: "Coding", text: "Coding", value: "Coding" },
  { key: "Comedy", text: "Comedy", value: "Comedy" },
  { key: "Cooking", text: "Cooking", value: "Cooking" },
  { key: "DIY / Crafts", text: "DIY / Crafts", value: "DIY / Crafts" },
  { key: "Design/Art", text: "Design/Art", value: "Design/Art" },
  { key: "Education", text: "Education", value: "Education" },
  { key: "Family", text: "Family", value: "Family" },
  { key: "Fashion", text: "Fashion", value: "Fashion" },
  { key: "Gaming", text: "Gaming", value: "Gaming" },
  {
    key: "Health & Fitness",
    text: "Health & Fitness",
    value: "Health & Fitness",
  },
  { key: "How-Tos", text: "How-Tos", value: "How-Tos" },
  { key: "Interviews", text: "Interviews", value: "Interviews" },
  {
    key: "Investing & Finance",
    text: "Investing & Finance",
    value: "Investing & Finance",
  },
  { key: "Language", text: "Language", value: "Language" },
  { key: "Music / Dance", text: "Music / Dance", value: "Music / Dance" },
  {
    key: "News / Commentary",
    text: "News / Commentary",
    value: "News / Commentary",
  },
  { key: "Podcasts", text: "Podcasts", value: "Podcasts" },
  {
    key: "Pranks/Challenges",
    text: "Pranks/Challenges",
    value: "Pranks/Challenges",
  },
  { key: "Reviews", text: "Reviews", value: "Reviews" },
  { key: "Shopping", text: "Shopping", value: "Shopping" },
  { key: "Sports", text: "Sports", value: "Sports" },
  { key: "Tech", text: "Tech", value: "Tech" },
  {
    key: "Television & Movies",
    text: "Television & Movies",
    value: "Television & Movies",
  },
  { key: "Travel", text: "Travel", value: "Travel" },
  { key: "Unboxing", text: "Unboxing", value: "Unboxing" },
  { key: "Vlogging", text: "Vlogging", value: "Vlogging" },
];

const subTiers = [
  { key: "0", text: "Any Subscriber Count", value: "0" },
  { key: "1000", text: "At least 1000 Subs", value: "1000" },
  { key: "5000", text: "At least 5000 Subs", value: "5000" },
  { key: "10000", text: "At least 10000 Subs", value: "10000" },
  { key: "50000", text: "At least 50000 Subs", value: "50000" },
  { key: "100000", text: "At least 100000 Subs", value: "100000" },
  { key: "1000000", text: "At least 1000000 Subs", value: "1000000" },
  { key: "5000000", text: "At least 5000000 Subs", value: "5000000" },
  { key: "10000000", text: "At least 10000000 Subs", value: "10000000" },
];

const ageTiers = [
  { key: "14", text: "14+ (with parental consent)", value: "14" },
  { key: "16", text: "16+ (with parental consent)", value: "16" },
  { key: "18", text: "18+", value: "18" },
  { key: "21", text: "21+", value: "21" },
  { key: "25", text: "25+", value: "25" },
];

function CollabForm(props) {
  const [errors, setErrors] = useState({});
  const { values, onChange, onSubmit } = useForm(createCollabPostCallback, {
    category: "",
    title: "",
    body: "",
    minSubCount: "",
    minAge: "",
    collabLocation: "",
  });

  const [createPost, { loading }] = useMutation(CREATE_POST_MUTATION, {
    variables: values,
    update(_, result) {
      const postId = result.data.createCollabPost.id;
      window.location.href = `/collab/${postId}`;
      /*
      values.title = "";
      values.body = "";
      values.collabLocation = "";
      */
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
  });

  function createCollabPostCallback() {
    createPost();
  }

  return (
    <>
      <h3>Post a Request for Collaboration</h3>
      {Object.keys(errors).length > 0 && (
        <div className="ui error message">
          <ul className="list">
            {Object.values(errors).map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>
      )}

      <Form onSubmit={onSubmit} noValidate className={loading ? "loading" : ""}>
        <Form.Select
          label="Video Genre"
          options={categories}
          placeholder="Select a category"
          name="category"
          onChange={onChange}
          selected={values.category}
          error={errors.category ? true : false}
        />
        <Form.Input
          fluid
          label="Post title"
          placeholder="Creators wanted!"
          name="title"
          onChange={onChange}
          value={values.title}
          error={errors.title ? true : false}
        />

        <Form.TextArea
          fluid
          label="Collab Description"
          placeholder="Here are the details about this collab..."
          name="body"
          onChange={onChange}
          value={values.body}
          error={errors.body ? true : false}
        />

        <h4>Eligibility</h4>
        <Form.Group widths="equal">
          <Form.Select
            label="Required Sub Count"
            options={subTiers}
            placeholder="Select minimum sub count"
            name="minSubCount"
            onChange={onChange}
            selected={values.minSubCount}
            error={errors.minSubCount ? true : false}
          />
          <Form.Select
            label="Minimun Age"
            options={ageTiers}
            placeholder="Select minimum age"
            name="minAge"
            onChange={onChange}
            selected={values.minAge}
            error={errors.minAge ? true : false}
          />
          <Form.Input
            fluid
            label="Must be located in"
            placeholder="Anywhere"
            name="collabLocation"
            onChange={onChange}
            value={values.collabLocation}
            error={errors.collabLocation ? true : false}
          />
        </Form.Group>
        <Button type="submit" color="teal">
          Submt
        </Button>
      </Form>
    </>
  );
}

const CREATE_POST_MUTATION = gql`
  mutation createCollabPost(
    $title: String
    $body: String
    $category: String
    $minAge: String
    $minSubCount: String
    $collabLocation: String
  ) {
    createCollabPost(
      collabPostInput: {
        title: $title
        body: $body
        category: $category
        minAge: $minAge
        minSubCount: $minSubCount
        collabLocation: $collabLocation
      }
    ) {
      id
    }
  }
`;

export default CollabForm;
